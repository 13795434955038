











import Vue from 'vue';
import ToastMessage from '@/models/ToastMessage';
import AdminUserService from '@/services/admin/AdminUserService';

export default Vue.extend({
  props: {
    passwordChangeUserName: {
      type: String,
    },
  },
  data() {
    return {
      newPassword: '',
    };
  },
  computed: {
    passwordChangeModalIsOpen(): boolean {
      return this.passwordChangeUserName !== '';
    },
  },
  methods: {
    updateShow(show: boolean) {
      if (show === false) {
        this.$emit('success');
      }
    },

    changePassword() {
      AdminUserService.changePassword(this.passwordChangeUserName, this.newPassword).then(() => {
        this.$store.commit('addToastMessage', new ToastMessage('Password erfolgreich geändert', 'bg-success'));
        this.$emit('success');
      });
    },
  },
});
