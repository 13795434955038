

















































































































import Vue, { VueConstructor } from 'vue';
import AdminUserService from '@/services/admin/AdminUserService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import DataTable from '@/components/shared/DataTable.vue';
import AdminChangePassword from '@/components/users/AdminChangePassword.vue';
import DeleteConfirmationModal from '@/components/messages/DeleteConfirmationModal.vue';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import ToastMessage from '@/models/ToastMessage';
import { MetaInfo } from 'vue-meta';

class AdminUserIndexDto {
  id!: string;
  userName!: string;
  createdAt!: Date;
  lastLoginAt!: Date;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [FormaterMixin],
  components: { FormatedTimestamp, DataTable, AdminChangePassword, DeleteConfirmationModal },
  data() {
    return {
      users: [],
      tableFilterValue: '',
      pages: 0,
      sorterValue: { column: 'createdAt', asc: true },
      currentPage: 1,
      loading: false,
      passwordChangeUserName: '',
      showDeleteModal: false,
      currentIndex: -1,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.admin.AdminHostIndex.meta.title').toString(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  methods: {
    openPasswordChangeModal(index: number) {
      const user = this.users[index] as AdminUserIndexDto;
      this.passwordChangeUserName = user.userName;
    },

    closePasswordChangeModal() {
      this.passwordChangeUserName = '';
    },

    confirmEmail(user: { id: string }) {
      AdminUserService.confirmUserEmail(user.id).then(() => {
        this.loadData();
      });
    },

    loadData() {
      this.loading = true;
      AdminUserService.getOwnerIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.users = res.value.users.items.map((u: AdminUserIndexDto) => ({
          ...u,
          createdAt: new Date(u.createdAt),
          lastLoginAt: new Date(u.lastLoginAt),
        }));
        this.pages = res.value.users.totalPages;
      });
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.admin.AdminHostIndex.breadcrumb.last' },
      ]);
    },

    loginAs(user: { userName: string }) {
      this.$store.dispatch('AuthStoreModule/loginAs', user.userName).then(() => {
        this.$router.push('/');
      });
    },

    deleteUser() {
      const user = this.users[this.currentIndex] as AdminUserIndexDto;
      AdminUserService.delete(user.id).then(() => {
        this.users.splice(this.currentIndex, 1);
        this.closeDeleteModal();
      });
    },

    openDeleteModal(index: number) {
      this.currentIndex = index;
      this.showDeleteModal = true;
    },

    closeDeleteModal() {
      this.currentIndex = -1;
      this.showDeleteModal = false;
    },

    downloadInvoice(hostUserId: string, dateString: string) {
      this.$store.commit(
        'addToastMessage',
        new ToastMessage(
          'Ihre Rechnung wird generiert. Bitte haben Sie einen Moment Geduld. Der Download startet automatisch.',
          'bg-success'
        )
      );
      AdminUserService.downloadInvoice(hostUserId, dateString).then((res) => {
        var link = document.createElement('a');
        link.innerHTML = 'Download PDF file';
        link.download = 'Rechnung.pdf';
        link.href = 'data:application/octet-stream;base64,' + res.value;
        link.click();
      });
    },
  },
  created() {
    this.loadData();
    this.setBreadCrumb();
  },
});
